@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 1px solid #f3f3f3; /* Light grey */
    border-top: 1px solid #383636; /* Blue */
    border-radius: 50%;
    animation: spinner 0.75s linear infinite;
  }
  
  .spinner-container {
    position: absolute;
    display: grid;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }


  .load-backgound{
    backdrop-filter: blur(2px);
    border-radius: 30px;
    height: 500px;
    width: 700px;
    background-color: #0e1127a2;
    display: grid;
    justify-content: center;
    align-items: center;
  }