#toHomePage{
    width: fit-content;
    text-decoration: none;
    color: white;
    margin: 15px;
    margin-left: 50px;
}

#title{
    font-weight: 200;
    text-align: center;
    width: fit-content;
    padding-left: 35px;
    margin-top: 0;
    margin-bottom: 0;
}


#topBar{
    max-width: 100%;
    border-bottom: 2px #27486a solid;
    height: 18vh;
    background-color:#0e1127;
}
  
#logo{
    width:100px;
    height: 100px;
}
  
@media (max-width: 536px) {
    #toHomePage{
        width: fit-content;
        text-decoration: none;
        color: white;
        margin: 15px;
        margin-left: 0px;
    }
}