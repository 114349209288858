#RoommateInput{
    width: fit-content;
    margin-bottom: 50px;
}

#buttonC{
    margin: 5px;
    border-radius: 30%;
    width: 30px;
}

#confirm{
    margin-top: 10px;
    width: 400px;
}

#link{
    max-width: 120px;
}

#nameInput{
    height: 37px;
    font-size: 1rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    padding: 0.375rem 0.75rem;
    width: fit-content;
}

#roommateName::before{
    content: "Name"
}

#roommateNumber::before{
    content: "Number"
}

#userInputText{
    margin: 40px;
}

#roommateNumber, #roommateName{
    width: fit-content;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 0px;
    padding-right: 1px;
}

#userInformation{
    margin-top:50px;
}

#roommateInfo{
    border: 3px solid #b5bfc9;
    border-radius: 0.375rem;
    padding: 10px;
    width: 471px;
}   

#body{
    padding-top: 100px;
    min-height: 70vh;
    height: fit-content;
    background-color: #072239;
    color: white;
    padding-bottom: 10px;
}

#addUserNumber{
    margin-top: 20px;
}

#conName{
    margin-right: 20px;
}

#next{
    margin-top: 20px;
}

#provider{
    width: 200px;
    margin: 20px;
}

@media (max-width:594px){
    #addNumber{
        margin-left: 0px;
    }
    #conName{
        margin-right: 0px;
    }
}

@media (max-width:471px)
 {
    #roommateInfo{
        width: 70%;
    }
}