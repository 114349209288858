#phoneNumbers{
    margin-top: 50px;
    width: 100%;
    color: white;
    flex-direction: column;
}

#deleteAcct{
    margin: 20px;
}

#numberInput{
    margin-top: 25px;
    width: 75px;
    height: 37px;
}

#addNumber{
    margin-top: 20px;
    margin-left: 30px;
}

#deleteNumber{
    width:100px;    
    margin-left: 100px;
}

#roommateNumber{
    width: 100px;
    color: white;
}

#roommateName{
    color: white;
}

#welcomePersonal{
    padding-top: 50px;
    color:white;
    font-weight: 100;
}

#inputer{
    width: 560px;
}

.but{
    height: 45px;
    font-size: 14px;
    color: #fff;
    background-color: #496682;
    padding: 10px 30px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    border-radius: 13px;
    cursor: pointer;
}

.but:hover{
    background-color: #81b5e3;
    color: #ffffff;
    border: none;
}

#nameInputContainer{
    margin-right: 30px;
}

@media (max-width: 560px){
    #inputer{
        width: 100%;
    }
}

@media (max-width:468px) {
    #nameInputContainer{
        margin-right: 0px;
    }
}

@media (max-width:438px) {
    #inputer{
        max-width: 210px;
        width: 100%;
    }
}